<template>
  <section class="projects">
    <h2>Projects</h2>
    <div class="project" v-for="(project, index) in projects" :key="index">
      <h3>{{ project.title }}</h3>
      <p>{{ project.description }}</p>
    </div>
  </section>
</template>

<script setup>
import { ref } from 'vue';

const projects = ref([
  {
    title: 'Funny AI Chatbot',
    description: 'A chatbot that tells funny jokes and plays games with you using Python and HTML5.'
  },
  {
    title: 'Image Recognition App',
    description: 'An app that recognizes objects in images using C++ and AI technology.'
  },
  {
    title: 'Mobile Game',
    description: 'A mobile game developed in Java and Objective-C, focusing on fun gameplay and user engagement.'
  }
]);
</script>

<style scoped>
.projects {
  padding: 20px;
  background-color: #cce6ff; /* Light blue */
}

h2 {
  color: #333;
}

.project {
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 5px;
  background-color: #fff;
}

h3 {
  color: #333;
}

p {
  color: #555;
  line-height: 1.6;
}
</style>
